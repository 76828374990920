
.navbar-container {
    position: fixed;
    pointer-events: none;
    right: 0;
    top: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    margin: 0;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
   
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction:row;
         @media only screen and (max-width: 960px) {
            justify-content: space-around;
        }
    .nav-text { 
        margin-top: 10px;
        pointer-events: all;
        a {
            color: white;
            text-decoration: none;
            font-size: 2rem;
            font-family: 'Rock 3D', cursive;
            font-weight: 700;
            margin: 0 40px;
            @media only screen and (max-width: 960px) {
                font-size: 1.2rem;
                margin: 0 5px;
            }
        }
    }
}
