.card-container {
    width: 500px;
    height: 60%;
    margin: 10px;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    
    .title{
        font-size: 1.5rem;
        margin-top: 10px;
        height: 3.2rem;
        text-decoration: none;
        color: white;
        font-weight: 900;
    }
    .year{
        font-size: .5rem;
        margin-bottom: 10px;
    }
    .photo{
        width: 100%;
        flex-grow: 1;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
    }
}