@import url('https://fonts.googleapis.com/css2?family=Neucha&family=Roboto:wght@100;300;400;500;700&family=Rock+3D&display=swap');
@import 'react-awesome-slider/dist/styles.css';

body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(360deg, rgb(77, 21, 168) 0%, rgba(148,187,233,1) 100%);
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  border: none;
}
* {
  box-sizing: border-box;
}
.fade-enter {
    opacity: 0;
    transform: translate(0, 25px);
    z-index: 1;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);
  
    transition: opacity 500ms ease-out, transform 700ms ease;
  }
  .fade-exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate(0, 30px);
  
    transition: opacity 500ms ease-out, transform 700ms ease;
  }

  .awssld {
    --organic-arrow-thickness: 4px;
    --organic-arrow-height: 40px;
    --slider-height-percentage: 60%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #00ff0d !important;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #c4ff94 !important;
    --control-bullet-active-color: #00ff0d !important;
    --content-background-color: #00000000 !important;
  } 

  @keyframes jump{
    0%{
      transform:translateY(0px);
    }
    50%{
      transform:translateY(50px); 
    }
    100%{
      transform:translateY(0px);
    }
  }