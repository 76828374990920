.work-page {

    .back-button-container {
        text-decoration: none;
        color: white;
        text-align: start;
        font-size: 2rem;
        line-height: 2rem;
        vertical-align: top;
        display: inline-block;
        font-weight: 700;
        @media only screen and (max-width: 600px)  {
            margin-top: 50px;
        }
        .back-button {
            font-size: 2rem;
            vertical-align: top;
        }
    }

    .work-content{

        margin: 0 100px 100px 100px;
        color: white;
        @media only screen and (max-width: 600px)  {
            margin: 100px 20px;
        }
        
        .year{
            font-size: .5rem;
            margin-bottom: 10px;
        }
        .description{
            margin-top: 10px;
        }
        .video-container{
            padding:56.25% 0 40px;
            position:relative;
        }
        .video-frame{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
        .images{
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}