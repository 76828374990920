.info-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container{
        max-width: 60vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #ffffff;
        
        p {
            font-family: 'Neucha', cursive;
            // text-shadow: 0 0 10px #ffffff,
            //     0 0 20px #ffffff, 
            //     0 0 40px #ffffff;
            font-size: 1.5rem;
            text-align: center;
            line-height: 1.25;
        }
        
        
        .cv-table {
            font-family: 'Neucha', cursive;
            display: grid;
            grid-template-columns: 100px 1fr;
            width: 100%;
            margin: 10px 0;
            // text-shadow: 0 0 10px #ffffff,
            //     0 0 20px #ffffff, 
            //     0 0 40px #ffffff;
        }

        &.icons {
            flex-direction:row;
            .icon {
                margin: 40px;
                height: 60px;
                width: 60px;
                // filter: drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #ffffff);
                color: #ffffff;
            }
        }
    }
    @media only screen and (max-width: 760px){
        .container{
            width: 100%;
            min-height: 120vh;
            padding: 0;
        
            &.icons {
                flex-direction:row;
                .icon {
                    margin: 10px;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}